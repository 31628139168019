import { Container, DisplayObject, Graphics } from 'pixi.js';

import { Game } from '../../game';
import { EventTypes } from '../../global.d';
import { setIsInTransition } from '../../gql';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH } from '../background/config';
import { layerTransition } from '../components/layer/layer';
import { StrictSpine } from '../components/spine';
import { eventManager } from '../config';

import { SCENE_CHANGE_BACKGROUND_COLOR } from './config';

class SceneChange extends Container {
  private rect: Graphics;

  private spider: StrictSpine<'stagechange'>;

  private windowSize = { width: 0, height: 0, scale: 1 };

  constructor() {
    super();
    this.interactive = false;
    this.visible = false;

    this.rect = this.initRect();
    this.addChild(this.rect);

    const spider = Game.getInstance().maker.spine('stagechange');
    this.spider = spider;
    this.addChild(spider);

    eventManager.addListener(EventTypes.SCENE_CHANGE_DOWN, this.startChangeSceneAnimationToFreeSpin.bind(this));
    eventManager.addListener(EventTypes.SCENE_CHANGE_UP, this.startChangeSceneAnimationToBaseGame.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, (_width, _height, _x, _y, scale, _pivotX, _pivotY) => {
      this.windowSize.scale = scale;
    });

    this.parentLayer = layerTransition;
  }

  private initRect() {
    const rect = new Graphics();
    rect.beginFill(SCENE_CHANGE_BACKGROUND_COLOR);
    rect.drawRect(0, 0, BACKGROUND_SIZE_WIDTH, BACKGROUND_SIZE_HEIGHT);

    return rect;
  }

  private getFadeAnimation(object: DisplayObject, duration: number, begin: number, target: number) {
    const animation = new Tween({
      object: object,
      duration,
      property: TweenProperties.ALPHA,
      propertyBeginValue: begin,
      target: target,
    });
    return animation;
  }

  private setSpiderParam() {
    this.spider.scale.set(this.windowSize.scale);
    this.spider.position.y = this.windowSize.height / 2;
    this.spider.position.x = this.windowSize.width / 2;
  }

  private startChangeSceneAnimationToFreeSpin(callback?: () => void) {
    const animationGroup = new AnimationGroup();

    const inSpider = this.spider.getAnimation(0, 'fg_stagechange');
    this.spider.skeleton.setSkinByName('feauture_core');
    this.setSpiderParam();
    animationGroup.addAnimation(inSpider);

    const tween = Tween.createDelayAnimation(inSpider.duration / 2);
    if (callback) {
      tween.addOnComplete(() => {
        callback();
      });
    }
    animationGroup.addAnimation(tween);
    animationGroup.addOnComplete(() => {
      this.visible = false;
      setIsInTransition(false);
    });

    setIsInTransition(true);
    this.visible = true;
    eventManager.emit(EventTypes.START_GLITCH_ANIMATION, this.spider, inSpider.duration);
    animationGroup.start();
  }

  private startChangeSceneAnimationToBaseGame(callback?: () => void) {
    const animationGroup = new AnimationGroup();
    const inSpider = this.spider.getAnimation(0, 'bg_stagechange');
    this.spider.skeleton.setSkinByName('base_core');
    this.setSpiderParam();
    animationGroup.addAnimation(inSpider);

    const tween = Tween.createDelayAnimation(inSpider.duration / 2);
    if (callback) {
      tween.addOnComplete(() => {
        callback();
      });
    }
    animationGroup.addAnimation(tween);
    animationGroup.addOnComplete(() => {
      this.visible = false;
      setIsInTransition(false);
    });

    setIsInTransition(true);
    this.visible = true;
    eventManager.emit(EventTypes.START_GLITCH_ANIMATION, this.spider, inSpider.duration);
    animationGroup.start();
  }

  private resize(width: number, height: number): void {
    this.windowSize.width = width;
    this.windowSize.height = height;
  }
}
export default SceneChange;
