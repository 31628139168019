export const GLITCH_FILTER_TIME = 500;
export const GLITCH_FILTER_OPTIONS = {
  slices: 20,
  offset: -10,
  direction: 0,
  fillMode: 0,
  seed: 0,
  average: false,
  minSize: 8,
  sampleSize: 512,
  red: [2, -4],
  green: [6, -2],
  blue: [4, -6],
};
