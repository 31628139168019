import _ from 'lodash';

import { ISongs, audioSprite } from './sprite.generated';

export * from './sprite.generated';

export const audioSpriteVolume: Partial<Record<ISongs, number>> = {
  [ISongs.SONG_032_01_BaseGameBGM_Base]: 0.6,
  [ISongs.SONG_032_02_BaseGameBGM_Melo]: 0.7,
  [ISongs.SONG_032_03_FreeSpinBGM]: 0.7,
  [ISongs.SONG_032_04_TotalWinBanner_A]: 0.7,
  [ISongs.SONG_032_05_TotalWinBanner_B]: 0.7,
  [ISongs.SONG_032_06_BigWin_Loop]: 0.8,
  [ISongs.SONG_032_07_BigWin_End]: 0.8,
  [ISongs.SONG_032_08_CountUp_Loop]: 0.7,
  [ISongs.SONG_032_09_CountUp_End]: 0.7,
  [ISongs.SONG_032_10_Spider_roll]: 0.8,
  [ISongs.SONG_032_11_Spider_pulse]: 0.8,
  [ISongs.SONG_032_12_Symbol_Stop]: 0.8,
  [ISongs.SONG_032_13_Symbol_Lockon]: 0.8,
  [ISongs.SONG_032_14_Symbol_Win]: 0.8,
  [ISongs.SONG_032_15_Cascade]: 0.8,
  [ISongs.SONG_032_16_Spin_End]: 0.8,
  [ISongs.SONG_032_17_BuyFeature]: 0.8,
  [ISongs.SONG_FeatureTrigger]: 0.8,
  [ISongs.SONG_SFX_UI_AutoSpin]: 0.8,
  [ISongs.SONG_SFX_UI_BetChange]: 0.8,
  [ISongs.SONG_SFX_UI_Close]: 0.8,
  [ISongs.SONG_SFX_UI_General]: 0.8,
  [ISongs.SONG_SFX_UI_Hover]: 0.8,
  [ISongs.SONG_SFX_UI_MaxBet]: 0.8,
  [ISongs.SONG_SFX_UI_MenuOpen]: 0.8,
  [ISongs.SONG_SFX_UI_SpinStart]: 0.8,
  [ISongs.SONG_SFX_Win_Big]: 0.8,
  [ISongs.SONG_SFX_Win_Epic]: 1.0,
  [ISongs.SONG_SFX_Win_Great]: 0.9,
  [ISongs.SONG_SFX_Win_Mega]: 0.8,
  [ISongs.SONG_Yocho_Phoenix]: 1.0,
};

const audioSpriteGaps: { [key in ISongs]?: number } = {};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
