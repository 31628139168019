import { Variables } from '../../../config';

export const textStyle = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#ffffff'],
  fillGradientType: 0,
  stroke: '#ee0000',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 3,
  miterLimit: 4,
};
