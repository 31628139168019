/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { CascadeFallPossibleSymbol, EventTypes } from '../../global.d';
import { setGameMode } from '../../gql';
import { isFreeSpinMode } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import { ZOrderReelContainer } from '../components/layer/config';
import { layerReel } from '../components/layer/layer';
import { FAKE_ROLLING_DURATION, REELS_AMOUNT, eventManager } from '../config';

import { DefaultSymbol, ScatterSymbol } from './slot';
import { CascadeAnimation } from './spin';

const baseGameOuterToInnerStepIndexes = [[0], [0, 1], [0, 1, 2], [0, 1, 2, 3]];

const freeSpinsOuterToInnerStepIndexes = [[0], [0, 1], [0, 1, 2], [0, 1, 2, 3], [0, 1, 2, 3, 4]];

export class Reel {
  public id: number;

  private symbols: SlotId[] = [];

  private animationSymbols: DefaultSymbol[] = [];

  private idleSymbols: DefaultSymbol[] = [];

  public container: Container;

  public cascadeAnimation?: CascadeAnimation;

  constructor(id: number, symbols: SlotId[]) {
    this.id = id;
    this.container = new Container();
    this.container.angle = (360 / REELS_AMOUNT) * id;

    this.initIdleSlots(symbols);
    this.idleSlots();

    this.container.zOrder = ZOrderReelContainer.NORMAL_REEL;
    this.container.parentLayer = layerReel;
  }

  private clearIdleSlots(): void {
    this.idleSymbols.forEach((v) => this.container.removeChild(v.spine));
    this.idleSymbols = [];
  }

  public createIdleSlots(symbols: SlotId[]) {
    symbols.forEach((symbol) => {
      const slotSymbol = symbol === SlotId.SC ? new ScatterSymbol(symbol) : new DefaultSymbol(symbol);
      this.idleSymbols.push(slotSymbol);
      this.container.addChild(slotSymbol.spine);
    });
  }

  public initIdleSlots(symbols: SlotId[]) {
    this.symbols = symbols;
    this.clearIdleSlots();
    this.createIdleSlots(symbols);
  }

  private clearAnimationSlots(): void {
    this.animationSymbols.forEach((v) => this.container.removeChild(v.spine));
    this.animationSymbols = [];
  }

  public createAnimationSlots(symbols: SlotId[]) {
    symbols.forEach((symbol) => {
      const slotSymbol = symbol === SlotId.SC ? new ScatterSymbol(symbol) : new DefaultSymbol(symbol);
      this.animationSymbols.push(slotSymbol);
      this.container.addChild(slotSymbol.spine);
    });
  }

  public initAnimationSlots(symbols: SlotId[]) {
    this.symbols = symbols;
    this.clearAnimationSlots();
    this.createAnimationSlots(symbols);
  }

  public hideAnimationSlots() {
    this.animationSymbols.forEach((slot) => (slot.spine.visible = false));
  }

  public showAnimationSlots() {
    this.animationSymbols.forEach((slot) => (slot.spine.visible = true));
  }

  public createSpinAnimation() {
    const disappearingAnimation = new AnimationChain({ isLoop: false });

    this.initAnimationSlots(this.symbols);

    disappearingAnimation.addOnStart(() => {
      this.hideIdleSlots();
      this.startStopAnimation();
    });

    this.animationSymbols.forEach((_, i) => {
      const animationGroup = new AnimationGroup();
      const cascadeSymbols = this.animationSymbols.slice(0, this.animationSymbols.length - i);
      const disappearSymbol = this.animationSymbols[this.animationSymbols.length - i];
      animationGroup.addOnStart(() => {
        disappearSymbol?.setSymbolSkin('default');
      });
      cascadeSymbols.forEach((symbol, j) => {
        const index = i + j;
        const animation = symbol.getSpineAnimation(index, 'stop');
        animationGroup.addAnimation(animation);
      });
      disappearingAnimation.appendAnimation(animationGroup);
      disappearingAnimation.appendAnimation(Tween.createDelayAnimation(33));
    });

    disappearingAnimation.addOnComplete(() => {
      this.animationSymbols.forEach((slot) => slot.setSymbolSkin('default'));
    });

    this.cascadeAnimation = new CascadeAnimation({
      disappearingAnimation: disappearingAnimation,
      waitingAnimation: Tween.createDelayAnimation(FAKE_ROLLING_DURATION),
    });

    return this.cascadeAnimation;
  }

  private createAppearingAnimation() {
    const appearingAnimation = new AnimationChain();

    // wait between reels
    appearingAnimation.appendAnimation(Tween.createDelayAnimation(this.id * 33));

    const cascadeStepSymbols: DefaultSymbol[][] = [];
    this.animationSymbols.forEach((_, i) => {
      cascadeStepSymbols.push(this.animationSymbols.slice(0, i + 1));
    });

    const animationStepIndexes = isFreeSpinMode(setGameMode())
      ? freeSpinsOuterToInnerStepIndexes
      : baseGameOuterToInnerStepIndexes;

    cascadeStepSymbols.forEach((slots, i) => {
      const animationGroup = new AnimationGroup();
      slots.forEach((slot, j) => {
        const index = animationStepIndexes[i]![j]!;
        const animation = slot.getSpineAnimation(index, 'stop');
        animation.addOnStart(() => {
          if (!slot.spine.visible) slot.spine.visible = true;
          if (slot.slotId !== SlotId.SC) slot.setSymbolSkin();
        });
        animationGroup.addAnimation(animation);
      });
      appearingAnimation.appendAnimation(animationGroup);
      appearingAnimation.appendAnimation(Tween.createDelayAnimation(1));
    });

    return appearingAnimation;
  }

  public startSpinStopAnimation(symbols: SlotId[]) {
    this.initAnimationSlots(symbols);
    this.animationSymbols.forEach((slot) => (slot.spine.visible = false));

    const appearingAnimation = this.createAppearingAnimation();
    this.cascadeAnimation?.appendAnimation(appearingAnimation);

    const waitingAnimation = this.cascadeAnimation!.getWaiting();
    if (waitingAnimation.ended) {
      waitingAnimation.duration = 1;
      waitingAnimation.start();
    }
    // when all reel stops
    if (this.id === REELS_AMOUNT - 1) {
      const duration = AudioApi.durationOf(ISongs.SONG_032_12_Symbol_Stop);
      appearingAnimation.appendAnimation(Tween.createDelayAnimation(duration));
      appearingAnimation.addOnStart(() => {
        AudioApi.play({ type: ISongs.SONG_032_12_Symbol_Stop });
      });
      appearingAnimation.addOnComplete(() => {
        eventManager.emit(EventTypes.REELS_STOPPED, false);
      });
    }

    waitingAnimation.end();
  }

  public idleSlots() {
    this.idleSymbols.forEach((slot, i) => {
      slot.slotId = this.symbols[i]!;
      slot.spine.visible = true;
    });
    this.idleSymbols.forEach((slot, index) => slot.startStopAnimation(index));
  }

  public hideIdleSlots() {
    this.idleSymbols.forEach((slot) => (slot.spine.visible = false));
  }

  public startStopAnimation() {
    this.animationSymbols.forEach((slot, i) => {
      if (this.symbols[i] !== SlotId.SC) {
        slot.setSymbolSkin();
      }
    });
    this.animationSymbols.forEach((slot, index) => slot.startStopAnimation(index));
  }

  public createStopAnimation(symbols: SlotId[]) {
    const animationGroup = new AnimationGroup();
    this.animationSymbols.forEach((slot, i) => {
      slot.slotId = symbols[i]!;
      slot.setSymbolSkin();
      const stop = slot.getSpineAnimation(i, 'stop');
      animationGroup.addAnimation(stop);
    });
    return animationGroup;
  }

  public createWinAnimation(winSlotIndexes: number[]) {
    const animationGroup = new AnimationGroup();
    this.animationSymbols.forEach((slot, index) => {
      if (winSlotIndexes.includes(index)) {
        const win = slot.getSpineAnimation(index, 'win');
        animationGroup.addAnimation(win);
      }
    });

    return animationGroup;
  }

  public createLostAnimation(winSlotIndexes: number[]) {
    const indexes: number[] = [];
    const animationGroup = new AnimationGroup();
    this.animationSymbols.forEach((slot, index) => {
      if (winSlotIndexes.includes(index)) {
        const win = slot.getSpineAnimation(index, 'lost');
        animationGroup.addAnimation(win);
        indexes.push(index);
      }
    });
    console.log(`lost id=${this.id} index=${indexes}`);
    return animationGroup;
  }

  public createRemainSymbolCascadeAnimation(remainingSymbols: CascadeFallPossibleSymbol[]) {
    const indexes: number[] = [];
    const animationChain = new AnimationChain();
    for (let i = 0; i < remainingSymbols.length - 1; i++) {
      const id = remainingSymbols[i];
      if (id !== '') {
        const animationIndexes = remainingSymbols.reduce<number[]>((acc, v, j) => {
          if (j > i && v === '') {
            acc.push(i + 1 + acc.length);
            return acc;
          }
          return acc;
        }, []);
        if (animationIndexes.length > 0) {
          const slot = this.animationSymbols[i]!;
          animationIndexes.forEach((index) => {
            animationChain.appendAnimation(slot.getSpineAnimation(index, 'stop'));
            animationChain.appendAnimation(Tween.createDelayAnimation(30));
            indexes.push(index);
          });
          animationChain.appendAnimation(Tween.createDelayAnimation(60));
        }
      }
    }
    console.log(`remain id=${this.id} index=${indexes}`);
    return animationChain;
  }

  public createNewSymbolCascadeAnimation(remainIndexes: number[], fallSymbols: SlotId[], _cascadedSymbols: SlotId[]) {
    const animationChain = new AnimationChain();

    AudioApi.play({ type: ISongs.SONG_032_15_Cascade });

    const reverse = [...fallSymbols].reverse();
    const reverseIndexes = [...fallSymbols].flatMap((_, i) => i).reverse();
    reverse.forEach((symbol, j) => {
      const i = reverseIndexes[j]!;
      const animationIndexes = isFreeSpinMode(setGameMode())
        ? freeSpinsOuterToInnerStepIndexes[i]!
        : baseGameOuterToInnerStepIndexes[i]!;
      const slot = this.animationSymbols[remainIndexes[i]!]!;
      slot.slotId = fallSymbols[i]!;
      animationIndexes.forEach((index) => {
        if (index === 0) {
          if (symbol !== SlotId.SC) {
            slot.setSymbolSkin();
          }
        }
        animationChain.appendAnimation(slot.getSpineAnimation(index, 'stop'));
        animationChain.appendAnimation(Tween.createDelayAnimation(30));
      });
      animationChain.appendAnimation(Tween.createDelayAnimation(60));
    });
    return animationChain;
  }
}
