import { TextStyle } from 'pixi.js';

import { Variables } from '../../config';

const GRADIENT_GOLD_TEXT_COLOR_CONFIG = {
  fill: ['#47fd53aa', '#47fd53cc', '#47fd53', '#47fd53cc', '#47fd53aa'],
  stroke: '#11111166',
  fillGradientType: 0,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  miterLimit: 1,
};
const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fill: ['#fd4766', '#fd47cc', '#fd47f2ff', '#fd47cc', '#fd4766'],
  stroke: '#11111166',
  fillGradientType: 0,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  miterLimit: 1,
};
export const buyFeatureTitleStyle = new TextStyle({
  fontSize: 80,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 8,
  lineJoin: 'round',
});
export const buyFeatureConfirmStyle = new TextStyle({
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#fd4766', '#fd47cc', '#fd47f2ff', '#fd47cc', '#fd4766'],
  stroke: '#111111',
  fillGradientType: 0,
  strokeThickness: 5,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 1,
  miterLimit: 1,
  lineJoin: 'round',
});
export const totalCostTextStyle = new TextStyle({
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 5,
  lineJoin: 'round',
});
export const totalCostTextAmountStyle = new TextStyle({
  fontSize: 100,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 65,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 10,
  lineJoin: 'round',
});
export const amountTextStyle = new TextStyle({
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 50,
  fill: ['#ffffffaa', '#ffffffcc', '#ffffffee', '#ffffffcc', '#ffffffaa'],
  stroke: '#111111',
  strokeThickness: 5,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  wordWrap: false,
  wordWrapWidth: 400,
  breakWords: true,
  miterLimit: 1,
  lineJoin: 'round',
});
export const betValueStyle = new TextStyle({
  fontSize: 80,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 50,
  fill: ['#ffffffaa', '#ffffffcc', '#ffffffee', '#ffffffcc', '#ffffffaa'],
  stroke: '#111111',
  strokeThickness: 8,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  miterLimit: 1,
  lineJoin: 'round',
});
export const betValueStyleConfirm = new TextStyle({
  fontSize: 90,
  fontFamily: Variables.FONT_FAMILY,
  lineHeight: 50,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 9,
  lineJoin: 'round',
});
