import { TextStyle } from 'pixi.js';

import { Variables } from '../../config';

const GRADIENT_GOLD_AMOUNT_COLOR_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#fd4766', '#fd47cc', '#fd47f2', '#fd47cc', '#fd4766'],
  fillGradientType: 0,
  stroke: '#ffffff',
  strokeThickness: 10,
  dropShadow: true,
  dropShadowColor: 0xff12f1,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 4,
  miterLimit: 1,
};
const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#fdfb47aa', '#fdfb47cc', '#fdfb47ff', '#fdfb47cc', '#fdfb47aa'],
  stroke: '#ffffff33',
  strokeThickness: 10,
  dropShadow: true,
  dropShadowColor: 0xff12f1,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 4,
  miterLimit: 1,
};
export const titleTextStyle = new TextStyle({
  fontSize: 100,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  lineJoin: 'round',
});
export const descriptionsTextStyle = {
  fontSize: 40,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#FFFFFF',
  stroke: '#000000',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAngle: 1,
  dropShadowDistance: 4,
  miterLimit: 4,
  lineJoin: 'round',
};
export const btnTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#fdfb47aa', '#fdfb47cc', '#fdfb47ff', '#fdfb47cc', '#fdfb47aa'],
  fontSize: 65,
  stroke: '#ffffff33',
  strokeThickness: 10,
  dropShadow: true,
  dropShadowColor: 0xff12f1,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 2,
  miterLimit: 4,
  lineJoin: 'round',
});
export const totalWinTitleStyles = {
  ...GRADIENT_GOLD_AMOUNT_COLOR_CONFIG,
  stroke: '#47FD53',
  fontSize: 100,
};
export const totalWinAmountTextStyles = new TextStyle({
  fontSize: 160,
  ...GRADIENT_GOLD_AMOUNT_COLOR_CONFIG,
  strokeThickness: 10,
  lineJoin: 'round',
});
