import { Variables } from '../../config';

const FREE_SPINS_COUNTER_TEXT_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#fdfb47ff'],
  fillGradientType: 0,
  stroke: '#FD4766',
  dropShadowColor: 0x333333,
  dropShadowDistance: 3,
  miterLimit: 1,
};
export const textStyle = {
  ...FREE_SPINS_COUNTER_TEXT_CONFIG,
  fontSize: 35,
  strokeThickness: 5,
};
export const spinsStyle = {
  ...FREE_SPINS_COUNTER_TEXT_CONFIG,
  fontSize: 45,
  strokeThickness: 6,
};
